<template>
  <v-container fluid grid-list-lg>
    <Create :createDialog="createDialog" @close="createDialog = false" @submit="getList"></Create>
    <Edit :editDialog="editDialog" @close="editDialog = false" @submit="getList" :editTechnician="selectedTechnician"></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle :showBackButton="true" title="Technician" @add="add"
        :color="$style.purchase.titlebar" />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" autofocus
            single-line hide-details clearable></v-text-field>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="technicians" :search="search" :loading="loading" :sort-by="sortBy"
          :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left" v-html="$highlight(item.name, search.toLowerCase())
                ">
                {{ item.name }}
              </td>
              <td class="text-left" v-html="$highlight(
                item.phoneNumber,
                search.toLowerCase()
              )
                ">
                {{ item.phoneNumber }}
              </td>
              <td class="text-left" v-html="$highlight(item.email, search.toLowerCase())
                ">
                {{ item.email }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="edit(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteTechnician(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import technicianService from "../service";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "technician-list",
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedTechnician: null,
      headers: [
        {
          text: this.$t("labels.name"),
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Phone Number",
          align: "left",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Email Address",
          value: "email",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      technicians: [],
      search: "",
      loading: true,
      page: 1,
      sortBy: "name",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Technician List";
  },
  watch: {
    search(val) {
      if (!val) {
        this.search = "";
      }
    },
  },
  components: {
    Create,
    Edit,
  },
  methods: {
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return technicianService.getAll().then((response) => {
        this.loading = false;
        this.technicians = response.data;
        return response;
      });
    },
    edit(technician) {
      this.selectedTechnician = technician;
      this.editDialog = !this.editDialog;
    },
    deleteTechnician(technician) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          technicianService.delete(technician.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Technician has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
